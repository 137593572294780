import React from 'react'

import styled from 'styled-components'
import { ContentContainer, BigHeader } from 'src/utils/styles/global-styles'
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import ProductsList from '../components/ProductsList'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO
      title="404: Not found"
      description="This URL is not valid. Please reach out to help@skylightframe.com if you need any assistance."
    />
    <ContentContainer>
      <Header>
        <Trans>Whoops!</Trans>
      </Header>
      <Message>
        <Trans>
          The page you were trying to get to can't be found and might have been moved or changed.
          Where shall we take you instead?
        </Trans>
      </Message>
      <ProductsList />
    </ContentContainer>
  </>
)

export default NotFoundPage

export const Header = styled(BigHeader)`
  margin: 3rem 0px 1rem 0px;
`
export const Message = styled.p`
  width: 500px;
  max-width: 90%;
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
